export enum SkillEnum {
  JAVA = 'JAVA',
  JAVA_EE = 'JAVA_EE',
  SPRING = 'SPRING',
  C_PLUS_PLUS = 'C_PLUS_PLUS',
  C_SHARP = 'C_SHARP',
  MVC_NET = 'MVC_NET',
  NET = 'NET',
  ANGULAR = 'ANGULAR',
  PYTHON = 'PYTHON',
  ANDROID = 'ANDROID',
  XAMARIN = 'XAMARIN',
  HTML = 'HTML',
  CSS = 'CSS',
  JAVASCRIPT = 'JAVASCRIPT',
  TYPESCRIPT = 'TYPESCRIPT',
  KEYCLOAK = 'KEYCLOAK',
  MYSQL = 'MYSQL',
  MONGODB = 'MONGODB',
  GITLAB_CI = 'GITLAB_CI',
  DOCKER = 'DOCKER',
  KUBERNETES = 'KUBERNETES',
  AZURE = 'AZURE',
  OPENSHIFT = 'OPENSHIFT',
  APACHE_TAPESTRY = 'APACHE_TAPESTRY'
}
